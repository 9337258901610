<template>
  <div class="container">
    <div v-if="!guid">
      <div class="large-12 medium-12 small-12 cell">
        <label>Upload some files
          <input type="file" id="files" ref="files" multiple v-on:change="handleFilesUpload()"/>
        </label>
      </div>
      <div class="large-12 medium-12 small-12 cell">
        <div v-for="(file, key) in files" v-bind:key="key" class="file-listing">{{ file.name }} <span class="remove-file" v-on:click="removeFile( key )">Remove</span></div>
      </div>
      <br>
      <div class="large-12 medium-12 small-12 cell">
        <button v-on:click="addFiles()">Add Files</button>
      </div>
      <br>
      <div class="large-12 medium-12 small-12 cell">
        <button v-on:click="submitFiles()">Submit</button>
      </div>
      <br>
      <div class="large-12 medium-12 small-12 cell" v-if="!textInputShown">
        <button v-on:click="inputText()">Input text</button>
      </div>
      <br>
      <div class="large-12 medium-12 small-12 cell" v-if="textInputShown">
        <label> Input some text to drop <br>
          <textarea v-model="textInputText" class="textinput"></textarea>
        </label>
        <br>
        <button v-on:click="addText()">Add Text</button>
      </div>
    </div>

    <div v-if="guid">
      Your download link is:
      <a :href="getLink()">{{getLink()}}</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
      files: [],
      guid: null,
      textInputShown: false,
      textInputText: ''
    }
  },
  methods: {
    getLink(){
      return `${window.location.origin}/#/download/${this.guid}`
    },
    addFiles(){
      this.$refs.files.click();
    },
    inputText(){
      this.textInputShown = true
    },
    addText(){
      if(this.inputText === '') return
      let fileHeader = this.textInputText.split('\n')[0]
      let fileName = fileHeader.length > 30 ? fileHeader.substring(0, 30) + `(len-${fileHeader.length})` : fileHeader;
      fileName = fileName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      let blob = new Blob([this.textInputText], {type : 'application/json'});
      let file = new window.File([blob], `${fileName}.txt`)

      this.files.push(file);
      this.textInputText = ''
      this.textInputShown = false
    },
    submitFiles(){
      if(this.textInputText !== ''){
        this.addText()
      }
      
      let formData = new FormData();
      for( let i = 0; i < this.files.length; i++ ){
        let file = this.files[i];

        formData.append('files[' + i + ']', file);
      }

      axios.post( `/api/files`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((res) => {
        this.guid = res.data.guid
      })
      .catch(() => {
        alert('An error happened, sorry...')
      });
    },
    handleFilesUpload(){
      let uploadedFiles = this.$refs.files.files;
      for( let i = 0; i < uploadedFiles.length; i++ ){
        this.files.push( uploadedFiles[i] );
      }
    },
    removeFile( key ){
      this.files.splice( key, 1 );
    }
  }
}
</script>

<style scoped>
  input[type="file"]{
    position: absolute;
    top: -500px;
  }

  div.file-listing{
    width: 200px;
  }

  span.remove-file{
    color: red;
    cursor: pointer;
    float: right;
  }
  .textinput{
    width: 100%;
    height: 500px;
  }
</style>
