<template>
  <div>
    <div v-if="loading">Loading...</div>
    <div v-if="files.length === 0 && !loading">Sorry, could not find any files for key: {{guid}}</div>
    <div v-if="files.length > 0">Files for: {{guid}}</div>
    <div v-if="files.length > 0">
      <div v-for="file in files" v-bind:key="file">
        <a :href="getLink(file)">{{file}}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: "Download",
    data(){
      return {
        files: [],
        guid: '--',
        loading: true
      }
    },
    methods:{
      getLink(file){
        return `/api/files/${this.guid}/${file}`
      }
    },
    mounted(){
      this.guid = this.$route.params.guid
      axios.get(`api/files?guid=${this.guid}`).then(res => {
        this.files = res.data
        this.loading = false
      })
    }
  }
</script>

<style scoped>

</style>