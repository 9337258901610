import Vue from 'vue'
import VueRouter from 'vue-router'
import Download from '../views/Download'
import Upload from '../views/Upload.vue'
import Home from '../views/Home.vue'
import Admin from '../views/Admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin
  },
  {
    path: '/upload',
    name: 'upload',
    component: Upload
  },
  {
    path: '/download/:guid',
    name: 'download',
    component: Download
  },

]

const router = new VueRouter({
  routes
})

export default router
