<template>
    <label>
      <input ref="textinput" type="text" placeholder="Enter key" class="filekey-input" @keydown.enter="go()" v-model="guid"/>
    </label>
</template>

<script>
  import axios from 'axios'
  export default {
    name: "Home",
    data(){
      return {
        guid: null
      }
    },
    methods:{
      async go() {
        if(!this.guid) return
        await this.$router.push({name: 'download', params: {guid: this.guid}})
      }
    },
    mounted(){
      this.$refs.textinput.focus()
      axios.get(`api/admin/check`).then(async () => {
        await this.$router.push({name: 'admin'})
      }).catch(() => {})
    }
  }
</script>

<style scoped>
  input{
    text-align: center;
  }

  .filekey-input{
    outline: none;
    border: none;
    border-bottom: #2c3e50 solid 1px;
    width: 250px;
  }
  .filekey-button{
    border: none;
    outline: none;
    background: #eef3f8;
    border-radius: 20%;
    padding: 5px;
    margin-left: 5px;
  }

</style>