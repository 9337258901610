<template>
  <div>
    <div class="upload-link">
      <router-link to="/upload" class="upload-link-inner">Upload some files</router-link>
    </div>
    <div v-if="loading">Loading...</div>
    <div v-if="uploads.length === 0 && !loading">Sorry, could not find any files</div>
    <div v-if="uploads.length > 0" class="upload-header">Uploads:</div>
    <div v-if="uploads.length > 0">
      <div v-for="upload in uploads" v-bind:key="upload.name">
        <router-link :to="getLink(upload)" class="folder-link">{{upload.name}} ({{upload.files.length}} files)</router-link>
        <button class="delete-button" @click="deleteUpload(upload.name)">Delete</button>
        <div v-for="file in upload.files" :key="file.name" class="file-holder">
          <a :href="getLink(upload, file.name)" class="file-link">{{file.name}}</a>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: "Admin",
    data(){
      return {
        uploads: [],
        loading: true
      }
    },
    methods:{
      getLink(upload, file){
        if(!file) return `/download/${upload.name}`
        return `/api/files/${upload.name}/${file}`
      },
      deleteUpload(uploadName){
        axios.delete(`api/admin/${uploadName}`).then(() => {
          this.init()
          this.loading = false
        })
      },
      init(){
        axios.get(`api/admin/list`).then(res => {
          this.uploads = res.data
          this.loading = false
        })
      }
    },
    mounted(){
      this.init()
    }
  }
</script>

<style scoped>
.upload-link{
  margin-bottom: 50px;
}
.upload-link-inner{
  text-decoration: none;
  color: #ff7f48;
  font-size: 20px;
}

.upload-header{
  font-size: 20px;
  margin-bottom: 10px;
}

.folder-link{
  color: #ff7f48;
  font-size: 20px;
  margin-bottom: 10px;
}

.file-holder{
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 20px;
} 

.file-link{


}

.delete-button{
  outline: none;
  border: none;
  background-color: #ff7f48;
  border-radius: 20%;
  margin-left: 10px;
}

@media screen and (max-width: 450px) {
  .headerbox{
    margin: 100px auto 50px auto;
    width: 350px;
  }
  .headerbox:before{
    background-color: #ff7f48;
    width: 100px;
    height: 50px;
    content: "";
    pointer-events: none;
    position: absolute;
    z-index: -1;
    transform: translateX(-85px) translateY(-15px) rotate(0deg);
  }
}
</style>